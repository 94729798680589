import './create.css';

function Create() {

    return(
        <div className='logged-in-container'>
            <p className='logged-in'>Welcome! You are logged in.</p>
        </div>
    );
};

export default Create;